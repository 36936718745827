<template>
  <NuxtImg
    v-if="imageSupported && props.src"
    :src="props.src"
    :width="imageDimensions.width"
    :height="imageDimensions.height"
    :loading="props.loading"
    :fetchpriority="props.fetchpriority"
    :sizes="props.sizes"
    :alt="props.alt ?? ''"
    :title="props.title"
    :decoding="props.decoding"
  />
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useRatioCalc } from '../../utils/useRatioCalc'

const props = withDefaults(defineProps<ImageInterface>(), {
  loading: 'lazy',
  fetchpriority: 'low',
  width: null,
  height: null,
  originalWidth: null,
  originalHeight: null,
  sizes: null,
  alt: '',
  title: null,
  decoding: 'async',
})

const SUPPORTED_FILE_EXTENSIONS = [
  'png',
  'jpg',
  'jpeg',
  'webp',
  'avif',
  'gif',
  'svg',
  'bmp',
  'tiff',
]

interface ImageInterface {
  originalWidth?: number | string | null
  originalHeight?: number | string | null
  width?: number | string | null
  height?: number | string | null
  src: string
  loading?: 'eager' | 'lazy' | null
  fetchpriority?: 'high' | 'low' | 'auto' | null
  sizes?: string | null
  alt?: string | ''
  title?: string | null
  decoding?: 'async' | 'sync' | 'auto' | null
}

const imageDimensions = {
  width: props.originalWidth || props.width,
  height: props.originalHeight || props.height,
}

if (props.originalWidth && props.originalHeight) {
  const { newWidth, newHeight } = useRatioCalc(
    Number(props.originalWidth),
    Number(props.originalHeight),
    Number(props.width),
    Number(props.height),
  )

  if ((props.width && !props.height) || (!props.width && props.height)) {
    imageDimensions.width = newWidth
    imageDimensions.height = newHeight
  }
}

const FILE_EXTENSION = computed(
  (): string => props.src?.split('.').pop() as string,
)
const imageSupported = computed((): boolean =>
  SUPPORTED_FILE_EXTENSIONS.includes(FILE_EXTENSION.value),
)
</script>
