export interface NewSizeInterface {
  newWidth: number
  newHeight: number
}

/**
 * Calculate width/height based on provided original image size
 * @param {number} originalWidth - original image width (provided by API)
 * @param {number} originalHeight - original image height (provided by API)
 * @param {number|null} _width - desired image width (optional)
 * @param {number|null} _height - desired image height (optional)
 * @description For example, if you have an 400x300px img and you need to set width to 200px,
 * useRatioCalc(400, 300, 200, null) will return {width: 200, height: 150}
 */
export function useRatioCalc(
  originalWidth: number,
  originalHeight: number,
  _width: number | null,
  _height: number | null,
): NewSizeInterface {
  if (_width) {
    const preservedRatioHeight = (originalHeight / originalWidth) * _width
    return {
      newWidth: _width,
      newHeight: Math.round(preservedRatioHeight),
    }
  }

  if (_height) {
    const preservedRatioWidth = (originalWidth / originalHeight) * _height
    return {
      newWidth: Math.round(preservedRatioWidth),
      newHeight: _height,
    }
  }

  return {
    newWidth: originalWidth,
    newHeight: originalHeight,
  }
}
